/* eslint-disable no-undef */
import Vue from 'vue';
import Vuex from 'vuex';
import VueCookie from 'vue-cookies';
import _ from 'lodash';
import FMUsers from '../utenti-frontalini-meniscati';
Vue.use(Vuex);
Vue.use(VueCookie);

class AppState {
  constructor() {
    this.profileOpen = false;
    this.currentTab = 'tab-cart'; //tabella aperta del profilo ;

    this.frontaliniMeniscati = [];
    this.gtagId = 'G-33Q4BY6G3Q';
    this.utentiFrontaliniMeniscati = FMUsers;
    this.lenteSx = null;
    this.lenteDx = null;
    this.erroreLentiDialog = false;
    this.coloreMetalloAste = 'GUN';
    this.showDoYouWantLenses = false; // Ask the user if he is sure not to take any sight lenses
  }

}

export default new Vuex.Store({
  state: new AppState(),
  mutations: {
    setFrontaliniMeniscati(state, fm) {
      state.frontaliniMeniscati = fm;
    },

    setTemplesMetalColor(state, metalColor) {
      state.coloreMetalloAste = metalColor;
    },

    setLenteSx(state, l) {
      state.lenteSx = l;
    },

    setLenteDx(state, l) {
      state.lenteDx = l;
    },

    setShowDoYouWantLenses(state, p) {
      state.showDoYouWantLenses = p;
    },

    resetLentiVista(state) {
      state.configurator.lenteVistaDx = null;
      state.configurator.lenteVistaSx = null;
    },

    openCloseDialogErroreLenti(state, c) {
      state.erroreLentiDialog = c;
    },

    /**
     * Questa funzionae ripristina una configurazione passata come parametro
     * @param {object} state store
     * @param {object} configuration configurazione da ripristinare
     */
    restoreConfigurationObject(state, configuration) {
      state.configurator.frontale = configuration.frontale; //modello frontale

      state.configurator.calibro = configuration.calibro;
      state.configurator.naso = configuration.naso;
      state.configurator.asianFitting = configuration.asianFitting;
      state.configurator.colore = configuration.colore;
      state.configurator.finitura = configuration.finitura;
      state.configurator.coloreAste = configuration.coloreAste;
      state.configurator.coloreMetalloAste = configuration.ColoreMetalloAste;
      state.configurator.misuraAste = configuration.misuraAste;
      state.configurator.frontali.name = configuration.nome;
      state.configurator.frontali.nameColor = configuration.coloreNome;
      state.configurator.note = configuration.note;
    },

    setProfileOpen: (state, po) => {
      state.profileOpen = po;
    },
    setCurrentTab: (state, ct) => {
      state.currentTab = ct;
    }
  },
  getters: {
    frontaliniMeniscati: state => {
      return state.frontaliniMeniscati;
    },
    profileOpen: state => {
      return state.profileOpen;
    },
    currentTab: state => {
      return state.currentTab;
    },
    englishUserEnabled: state => {
      var _state$configurator$u, _state$configurator$u2;

      if (_.indexOf(['EN', 'GB', 'IE'], (_state$configurator$u = state.configurator.user) === null || _state$configurator$u === void 0 ? void 0 : (_state$configurator$u2 = _state$configurator$u.user) === null || _state$configurator$u2 === void 0 ? void 0 : _state$configurator$u2.country) >= 0) {
        var _state$configurator$u3, _state$configurator$u4;

        return ((_state$configurator$u3 = state.configurator.user) === null || _state$configurator$u3 === void 0 ? void 0 : (_state$configurator$u4 = _state$configurator$u3.user) === null || _state$configurator$u4 === void 0 ? void 0 : _state$configurator$u4.username) == 'C0000025556';
      }

      return true;
    },
    canOrderColorSample: (state, getters) => {
      return state.configurator.colore != null && !getters.userIsGuest;
    }
  }
});